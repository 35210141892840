export default function () {
	const select = document.querySelector('.hero__select select');
	const link = document.querySelector('.js-hero--cta');

	if (!select || !link) {
		return;
	}

	// eslint-disable
	select.addEventListener('change', ({ target: { value } }) => {
		link.href = value;
	});
}
