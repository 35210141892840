export default function prefooter() {
	const modalPopup = document.querySelector('.contact-modal');
	const modalClose = document.querySelector('.contact-modal__close');

	if (!modalPopup) {
		return;
	}
	modalClose.addEventListener('click', () => {
		modalPopup.close();
	});

	const prefooterLinks = document.querySelectorAll('.prefooter__link-link');

	if (prefooterLinks.length) {
		prefooterLinks.forEach((link) => {
			link.addEventListener('click', (e) => {
				// If href = #contact
				if (link.getAttribute('href') === '#contact') {
					e.preventDefault();
					modalPopup.showModal();
				}
			});
		});
	}

	const contactBlockEmail = document.querySelector('.contact__data-text--email');

	if (contactBlockEmail) {
		contactBlockEmail.addEventListener('click', (e) => {
			e.preventDefault();
			modalPopup.showModal();
		});
	}

	// Listen for click outside of modal
	window.addEventListener('click', (e) => {
		if (e.target === modalPopup) {
			modalPopup.close();
		}
	});
}
