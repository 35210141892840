/**
 * Global imports
 */
/* eslint-disable */
import svg4everybody from 'svg4everybody';
import MobileMenu from './components/mobile-menu';
import testimonialsSlider from './blocks/testimonials';
import accordion from './blocks/accordion';
import archiveNews from './blocks/archiveNews';
import hero from './blocks/hero';
import prefooter from './components/prefooter';

document.addEventListener('DOMContentLoaded', () => {
	svg4everybody();
	MobileMenu();
	accordion();
	prefooter();

	if (document.querySelector('.archive-news__categories')) {
		archiveNews();
	}

	// if (document.querySelector('.glossary__items')) {
	// 	glossary();
	// }

	if (document.querySelector('.hero')) {
		hero();
	}
});

const testimonialsSliderElement = document.querySelector('.testimonials__container');

if (testimonialsSliderElement) {
	testimonialsSlider();
}
