export default function accordion() {
	/*eslint-disable */
	/*eslint-disable */

	// Get all accordions
	const accordionItems = document.querySelectorAll('.image-text__accordion');

	// Loop through each accordion
	accordionItems.forEach(accordionItem => {
		// Get all accordion items
		const accordionItems = accordionItem.querySelectorAll('.image-text__row');

		// Loop through each accordion item
		accordionItems.forEach(accordionItem => {
			// Get the accordion item header
			const accordionItemHeader = accordionItem.querySelector('.image-text__row-title');

			// Get the accordion item content
			const accordionItemContent = accordionItem.querySelector('.image-text__row-text');

			// Listen for click event
			accordionItemHeader.addEventListener('click', event => {
				if (!accordionItem.classList.contains('collapsed')) {
					accordionItems.forEach(item => {
						item.classList.remove('collapsed');
						jQuery(item).find('.image-text__row-text').slideUp();
					});

					// Add class to sibling element with class plus-minus
					accordionItem.classList.toggle('collapsed');

					// Toggle the accordion item content
					jQuery(accordionItemContent).slideToggle();
				}
			});
		});
	});
}

