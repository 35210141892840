import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { closeLoader, showLoader } from '../global/toggleLoader';

const $ = jQuery;

export default function () {
	archiveSlider();
	categories();
	pagination();
}

function archiveSlider() {
	const swiper = new Swiper('.archive-news__categories', {
		modules: [Navigation],
		slidesPerView: 'auto',
		spaceBetween: 12,
		navigation: {
			nextEl: '.archive-news__navigation .swiper-button-next',
			prevEl: '.archive-news__navigation .swiper-button-prev',
		},
	});
}

function categories() {
	const categories = document.querySelectorAll('.archive-news__category');

	categories.forEach(category => {
		category.addEventListener('click', event => {
			event.preventDefault();

			if (!event.target.classList.contains('active')) {
				categories.forEach(cat => {
					cat.classList.remove('active');
				});

				event.target.classList.add('active');

				// Get Posts
				getPostsAjax();
			}
		});
	});
}

function pagination() {
	const numbers = document.querySelectorAll('.archive-news__pagination .page-numbers');

	numbers.forEach(number => {
		number.addEventListener('click', event => {
			event.preventDefault();
			let currentPagination = document.querySelector('.archive-news__pagination .page-numbers.current');

			if (!event.target.classList.contains('prev') && !event.target.classList.contains('next') && !event.target.classList.contains('dots')) {
				handlePagination(event.target);
			} else if (event.target.classList.contains('prev')) {
				if (currentPagination) {
					let prevElement = currentPagination.previousElementSibling;
					handlePagination(prevElement);
				}
			} else if (event.target.classList.contains('next')) {
				if (currentPagination) {
					let nextElement = currentPagination.nextElementSibling;
					handlePagination(nextElement);
				}
			}
		});
	});

	function handlePagination(target) {

		numbers.forEach(num => {
			num.classList.remove('current');
		});

		target.classList.add('current');

		// Get Posts
		getPostsAjax();
	}
}

function getPostsAjax() {
	const section = document.querySelector('.archive-news');
	const container = document.querySelector('.archive-news__container');
	const paginationContainer = document.querySelector('.archive-news__pagination');
	let data = new Object;
	let categoryId = document.querySelector('.archive-news__category.active').dataset.termId;
	let currentPagination = document.querySelector('.archive-news__pagination .page-numbers.current');

	if (categories) {
		data['categories'] = categoryId;
	}

	if (currentPagination) {
		data['paged'] = currentPagination.textContent;
	}

	$.ajax({
		type: 'GET',
		url: menaObject.ajaxurl,
		dataType: 'html', // add data type
		data: {
			action: 'menaGetPostsAjax',
			data: data,
		},
		beforeSend: function () {
			$(container).addClass('loading');
			showLoader(section);
		},
		success: function (response) {
			if (response) {
				response = JSON.parse(response);

				if (response.html) {
					// container.scrollIntoView({
					// 	behavior: 'smooth',
					// 	block   : 'start',
					// 	inline  : 'nearest',
					// });
					$(container).html(response.html);
				}

				if (response.pagination) {
					$(paginationContainer).html(response.pagination);
					pagination();
				} else {
					$(paginationContainer).html('');
				}

				$(container).removeClass('loading');
				closeLoader();
			}
		},
	});
}
