const $ = window.jQuery;

/**
 * Show loader
 */
export function showLoader(container) {
	const loader = `<div class="main-loader js-loader">
                        <div class="main-loader__container lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>`;

	const body = $('body');
	body.addClass('no-scroll');
	$(container).append(loader);
	$('.js-loader').addClass('loading');
}

/**
 * Remove Loader from body
 */
export function closeLoader() {
	$('body').removeClass('no-scroll');
	$('.js-loader').remove();
}

export default function (container) {
	if ($('.js-loader').length === 0) {
		showLoader(container);
	} else {
		closeLoader();
	}
}
