import Swiper from 'swiper';
// import swiper and Pagination module
import { Pagination } from 'swiper/modules';
// import styles
import 'swiper/css';

export default function testimonialsSlider() {
	const swiper = new Swiper('.testimonials__container', {
		modules: [Pagination],
		slidesPerView: 1.1,
		spaceBetween: 20,
		grabCursor: true,
		autoplay: {
			delay: 5000,
		},
		breakpoints: {
			600: {
				spaceBetween: 16,
				slidesPerView: 2,
				initialSlide: 1,
			},
			1024: {
				spaceBetween: 33,
				slidesPerView: 3,
				initialSlide: 1,
			},
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
	});
}
